<template>
  <v-container>
    <v-btn color="success" @click="$router.push('/createCategory')">
      Criar Categoria
      <v-icon>
        mdi-plus
      </v-icon>
    </v-btn>
    <v-alert v-if="message !== ''" type="success">
      {{ message }}
    </v-alert>
    <br />
    <v-data-table :loading="loading" :headers="headers" :items="categories" :items-per-page="5" class="elevation-1">
      <template v-slot:item.image="{ item }">
        <img width="150" height="100" :src="item.image" />
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="$router.push(`/editCategory/${item.id}`)">
          mdi-pencil
        </v-icon>
        <v-icon small class="mr-2" @click="$router.push(`/addMediaCategory/${item.id}`)">
          mdi-image
        </v-icon>
        <ModalDelete v-if="item.deletable" @click="deleteCategory(item.id)" />
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import axios from 'axios';
import { mapActions } from 'vuex';
import { getClassCategories } from '@/services/classItemService';
import ModalDelete from '@/components/shared/ModalDelete';

export default {
  components: {
    ModalDelete
  },
  methods: {
    ...mapActions("User", ["REQUEST_USERS"]),
    async deleteCategory(classId) {
      let url = `https://api.weflowoficial.com/api/v1/class_categories/${classId}`;

      axios.delete(url).then(() => {
        this.categories = this.categories.filter((item) => item.id !== classId)
        this.message = "Categoria excluída com sucesso!"
        setTimeout(() => {
          this.message = ""
        }, 1200)
      }).catch(err => {
        console.log(err)
        alert("erro ao deletar");
      }).finally(() => this.dialog = false);

    }
  },
  data() {
    return {
      categories: [],
      loading: false,
      message: "",
      headers: [
        { text: "Id", value: "id" },
        { text: "Titulo", value: "title" },
        { text: "Imagem", value: "image" },
        { text: "Ações", value: "actions" },
      ],
    };
  },
  async mounted() {
    this.loading = true
    const resp = await getClassCategories()
    this.loading = false
    this.categories = resp.data;
  },
};
</script>