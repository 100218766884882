<template>
  <v-container>
    <v-card :loading="loading" class="mx-auto my-12" max-width="475">
      <template slot="progress">
        <v-progress-linear color="deep-purple" height="10" indeterminate></v-progress-linear>
      </template>
      <v-img height="100" class="logo-form" src="/assets/logo_green.png"></v-img>

      <v-form @submit.prevent="submit" ref="form">
        <v-card-text>
          <v-alert type="success" v-if="successMsg">
            Parabéns sua mensagem foi enviada com sucesso!
          </v-alert>
          <v-row align="center" class="mx-0">
            <v-col cols="6" class="px-0 py-0">
              <v-text-field class="mx-2" v-model="form.title" label="Título"
                :rules="[(v) => !!v || 'Título é obrigatório']" hide-details="auto"></v-text-field>


            </v-col>
            <v-col cols="6" class="px-0 py-0">
              <v-text-field class="mx-2" v-model="form.order_number" label="Número de ordernação" type="number"
                :rules="[(v) => !!v || 'Número de ordernação é obrigatório']" hide-details="auto"></v-text-field>

            </v-col>

            <v-col class="px-0 py-0" cols="12">
              <v-text-field class="mx-2" v-model="form.description" label="Descrição"
                :rules="[(v) => !!v || 'Descrição é obrigatório']" hide-details="auto"></v-text-field>

            </v-col>

            <v-col cols="12" class="px-0 py-0">
              <v-checkbox cols="12" v-model="form.has_subcategory" :label="`Permite subcategoria?`" />
            </v-col>



          </v-row>
        </v-card-text>

        <v-card-text>
          <v-btn block type="submit" color="primary">
            <span v-if="loading"> Carregando </span>
            <span v-else> Editar </span>
          </v-btn>
        </v-card-text>
      </v-form>

      <div style="padding: 1rem;" v-if="form.has_subcategory">

        <h3> Subcategorias </h3>

        <v-btn color="success" class="mb-3" @click="goToCreateSubcategory()">
          Criar subcategoria
          <v-icon>
            mdi-plus
          </v-icon>
        </v-btn>
        <v-data-table :loading="loading" :headers="headers" :items="subcategories" :items-per-page="5"
          class="elevation-1">
          <template v-slot:item.image="{ item }">
            <img width="150" height="100" :src="item.image" />
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="$router.push(`/EditSubcategory/${item.id}`)">
              mdi-pencil
            </v-icon>
            <ModalDelete v-if="true" @click="deleteCategory(item.id)" />
          </template>
        </v-data-table>

      </div>

    </v-card>


  </v-container>
</template>


<script>
import { mapActions } from "vuex";
import ModalDelete from '@/components/shared/ModalDelete';

export default {
  components: {
    ModalDelete
  },
  mounted() {
    this.retrieveCategory();
    this.retrieveSubcategories();
  },
  methods: {
    ...mapActions("User", ["LOGIN_USER"]),
    goToCreateSubcategory() {
      this.$router.push(`/CreateSubcategory/${this.$route.params.id}`)
    },
    toggleLoading() {
      this.loading = !this.loading;
    },
    async retrieveCategory() {
      this.toggleLoading();
      const resp = await this.$api.get(`/class_categories/${this.$route.params.id}`)
      if (resp.status == 200) {
        this.form = { ...resp.data }
      } else {
        alert("erro")
      }

      this.toggleLoading();
    },
    async retrieveSubcategories() {
      const resp = await this.$api.get(`/subcategories_by_category/${this.$route.params.id}`);
      if (resp.status == 200) {
        this.subcategories = resp.data;
      } else {
        alert("Erro ao listar subcategorias");
      }
    },
    async deleteSubcategory(id) {
      this.toggleLoading();

      const resp = await this.$api.delete(`/subcategories/${id}`);
      if (resp.status == 200) {
        this.retrieveSubcategories();
      } else {
        alert("Erro ao deletar subcategoria");
      }
    },
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let id = this.$route.params.id;
        let url =
          `/class_categories/${id}`;
        console.log(this.form);

        const resp = await this.$api.put(url, {
          class_category: { ...this.form },
        });
        if (resp.status === 200) {
          this.loading = false;
          this.successMsg = true;
        } else {
          this.loading = false;
        }
      }
    },
  },
  data: () => ({
    formValid: true,
    loading: false,
    successMsg: false,
    validations: {},
    subcategories: [],
    headers: [
      { text: "ID", value: "id" },
      { text: "Descrição", value: "description" },
      { text: "Imagem", value: "image" },
      { text: "Ações", value: "actions" },
    ],
    form: {
      title: "",
      order_number: 0,
      description: "",
      has_subcategory: false,
    },
  }),
};
</script>

<style scoped>
.logo-form {
  width: 100px;
  margin-left: 35%;
  margin-top: 20px;
  height: 100px;
}
</style>