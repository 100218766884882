<template>
  <v-container>
    <v-card :loading="loading" class="mx-auto my-12" max-width="374">
      <template slot="progress">
        <v-progress-linear color="deep-purple" height="10" indeterminate></v-progress-linear>
      </template>
      <v-card-title>
        Criar Aula
      </v-card-title>

      <v-form @submit.prevent="submit" ref="form">
        <v-card-text>
          <v-alert type="success" v-if="successMsg">
            Parabéns sua aula foi criada com sucesso <a href="/classItems">Todas as aulas</a>
          </v-alert>
          <v-row align="center" class="mx-0">
            <v-text-field v-model="form.title" label="Título da Aula" :rules="[
      (v) => !!v || 'Título é obrigatório',
      (value) => (value || '').length <= 30 || 'Máximo de carctéres é 30',
    ]" hide-details="auto"></v-text-field><br />

            <br />
            <v-textarea :rules="[(v) => !!v || 'Descreva a aula']" v-model="form.description" name="input-7-1"
              label="Descrição da aula" value="" hint="Descrição"></v-textarea>

            <v-text-field v-model="form.duration_minutes" label="Duração (em minutos)" type="number"
              :rules="[(v) => !!v || 'Duração é obrigatória']" hide-details="auto"></v-text-field><br />

            <v-select v-model="form.class_category_id" :items="classCategories" item-text="title" item-value="id"
              @change="checkSubcategory(form.class_category_id)" label="Selecione a categoria" outlined
              dense></v-select>

            <v-select v-if="needSubcategory" v-model="form.subcategory_id" :items="subcategories"
              item-text="description" item-value="id" :rules="selectRules" label="Selecione a subcategoria" outlined
              dense></v-select>

            <v-select v-model="form.user_id" :items="retrievedUsers" item-text="first_name" item-value="id"
              label="Selecione o professor" outlined dense></v-select>

            <v-select v-model="form.class_level_id" :items="levels" item-text="level_name" item-value="id"
              label="Selecione o Nível da aula" outlined dense></v-select>

            <v-radio-group v-model="releaseType" row>
              <v-radio value="now" label="Disponibilizar agora"></v-radio>
              <v-radio value="scheduled" label="Agendar lançamento"></v-radio>
            </v-radio-group>

            <div class="release-date-div" v-if="releaseType == 'scheduled'">
              <v-text-field v-model="form.release_date" label="Data de lançamento" type="date"></v-text-field>
              <br />

              <v-text-field v-model="form.release_hour" label="Hora do lançamento" type="time"></v-text-field>
            </div>


          </v-row>
        </v-card-text>

        <v-card-text>
          <v-btn block type="submit" color="primary">
            <span v-if="loading"> Carregando </span>
            <span v-else> Enviar </span>
          </v-btn>
        </v-card-text>
      </v-form>
    </v-card>
  </v-container>
</template>


<script>
import { getClassCategories, getClassCategoriesSubcategories } from '@/services/classItemService';
import { getClassLevels } from '@/services/classLevelService';
import moment from 'moment';
moment.locale('pt-br')

export default {

  methods: {
    async retrieveCategories() {
      const resp = await getClassCategories()
      console.log('Resp data: ', resp.data);
      this.classCategories = resp.data;
    },
    async retrieveLevels() {
      const resp = await getClassLevels()
      this.levels = resp.data;
    },
    async retrieveTeachers() {
      const teachers = await this.$api.get("/teachers");
      this.retrievedUsers = teachers.data;
    },
    async checkSubcategory(id) {
      const category = this.classCategories.find((c) => c.id == id);
      console.log('Category: ', category);
      if (category) {
        this.needSubcategory = category.has_subcategory;

        if (category.has_subcategory) {
          this.getSubcategories(id);
        } else {
          this.form.subcategory_id = null;
        }
      }
    },
    async getSubcategories(id) {
      const resp = await getClassCategoriesSubcategories(id);
      console.log('Get subcategories: ', resp.data);
      if (resp.status == 200) {
        this.subcategories = resp.data;
      }
    },
    async submit() {
      try {
        if (this.$refs.form.validate()) {
          this.loading = true;
          let url = "/class_items";

          const data = {
            title: this.form.title,
            description: this.form.description,
            user_id: this.form.user_id,
            duration_minutes: this.form.duration_minutes,
            class_level_id: this.form.class_level_id,
            class_category_id: this.form.class_category_id,
            subcategory_id: this.form.subcategory_id ?? null,
            release_date: this.releaseType == 'now' ? null : moment(this.form.release_date + ' ' + this.form.release_hour).format('YYYY-MM-DD HH:mm:ss')
          };

          const resp = await this.$api.post(url, {
            class_item: {
              ...data
            },
          });
          if (resp.data) {
            this.loading = false;
            this.successMsg = true;
          } else {
            this.loading = false;
          }
        }
      } catch (e) {
        console.log('Catch item: ', e);
      }
      // console.log('Date: ', moment(this.form.release_date + ' ' + this.form.release_hour).format('YYYY-MM-DD HH:mm:ss'));

    },
  },
  async mounted() {
    this.retrieveCategories();
    this.retrieveLevels();
    this.retrieveTeachers();
  },
  computed: {
    selectRules() {
      return this.needSubcategory ? [(v) => !!v || 'Subcategoria é obrigatório!'] : [];
    }
  },
  data: () => ({
    formValid: true,
    loading: false,
    retrievedUsers: [],
    successMsg: false,
    classCategories: [],
    subcategories: [],
    needSubcategory: false,
    levels: [],
    validations: {},
    releaseType: "now",
    form: {
      email: "",
      city: "",
      password: "",
      class_category_id: 0,
      subcategory_id: null,
      duration_minutes: 0,
      name: "",
      user_id: 0,
      class_level_id: 0,
      message: "",
      phone: "",
      release_date: moment().format('YYYY-MM-DD'),
      release_hour: moment().format('HH:mm')
    },
  }),
};
</script>

<style scoped>
.logo-form {
  width: 100px;
  margin-left: 35%;
  margin-top: 20px;
  height: 100px;
}

.release-date-div {
  display: flex;
  width: 100%;
}

.release-date-div * {
  max-width: 50%;
  padding-inline: 0.1rem;
}
</style>