import Vue from 'vue';

const BASE_URL = 'https://api.weflowoficial.com/api/v1';
// const BASE_URL =  "http://localhost:3000/api/v1";


function uploadClassItem(id, formData) {
    const url = `${BASE_URL}/class_items/${id}`;
    return Vue.prototype.$api.put(url, formData)
        // get data
        .then(x => x.data)
        // add url field
        .then(x => x.map(img => Object.assign({},
            img, { url: `${BASE_URL}/images/${img.id}` })));
}

function uploadCategory(id, formData) {
    const url = `${BASE_URL}/class_categories/${id}`;
    return Vue.prototype.$api.put(url, formData)
        // get data
        .then(x => x.data)
        // add url field
        .then(x => x.map(img => Object.assign({},
            img, { url: `${BASE_URL}/images/${img.id}` })));
}

function uploadSubcategory(id, formData) {
    const url = `${BASE_URL}/subcategories/${id}`;
    return Vue.prototype.$api.put(url, formData)
        // get data
        .then(x => x.data)
        // add url field
        .then(x => x.map(img => Object.assign({},
            img, { url: `${BASE_URL}/images/${img.id}` })));
}

export { uploadClassItem, uploadCategory, uploadSubcategory}